import React from 'react';

import * as styles from './cta-card.module.scss';
import Button from './button';

/**
 * Call-to-Action Card renders a title and action in a prominent card style. Can
 * be optionally rendered with additional body content. When multiple CTACard
 * components are rendered in a group, they are spaced evenly in a grid format.
 *
 * @param actionText - What you want the user to do. Appears on the button.
 * @param children - Optional body content
 * @param handleClick - Click event for the action button
 * @param isButtonDisabled - Disables the action button
 * @param title - Card title
 */
const CTACard = ({
  actionText,
  children,
  handleClick,
  isButtonDisabled,
  title,
}: CTACardProps) => {
  return (
    <article className={styles.CTACard}>
      <h3 className={styles.CTACard__title}>{title}</h3>

      {children && <div className={styles.CTACard__body}>{children}</div>}

      <Button
        isDisabled={isButtonDisabled}
        isBlock={true}
        onClick={handleClick}
        size="large"
      >
        {actionText}
      </Button>
    </article>
  );
};

export interface CTACardProps {
  actionText: string;
  children?: React.ReactNode;
  handleClick: () => void;
  isButtonDisabled?: boolean;
  title: string;
}

export default CTACard;
