import React, { PropsWithChildren } from 'react';
import { PageProps } from 'gatsby';

import * as styles from './page.module.scss';
import SEO from './seo';
import { routeConfigMap } from '../constants/navigation';
import { PageRoute } from '../types/routes';

/**
 * Common page header, used on most brochure-style pages
 */
export const PageHeader = ({ location }: Pick<PageProps, 'location'>) => {
  const { title, subtitle } = routeConfigMap[location?.pathname as PageRoute];

  return (
    <header>
      <SEO title={title} />
      <h1 className={styles.Page__title}>{title}</h1>
      {subtitle && <h2 className={styles.Page__subtitle}>{subtitle}</h2>}
    </header>
  );
};

/**
 * Common page body, used on most brochure-style pages
 */
export const PageBody = ({
  children,
}: PropsWithChildren<Record<string, unknown>>) => (
  <div className={styles.Page__body}>{children}</div>
);

/**
 * Common page container, generally used to wrap a PageHeader and PageFooter
 */
const Page = ({ children }: PropsWithChildren<Record<string, unknown>>) => (
  <div className={styles.Page}>
    <div className={styles.Page__wrapper}>{children}</div>
  </div>
);

export default Page;
