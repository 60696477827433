import React, { useCallback, useEffect } from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { loadStripe } from '@stripe/stripe-js';
import { AppState, useAuth0 } from '@auth0/auth0-react';
import { nanoid } from 'nanoid';

import * as styles from './pricing.module.scss';
import {
  Auth0MirosignCustomNamespace,
  SubscriptionType,
} from '@common/types/user';
import useAPIHelper from '../hooks/api';
import CTACard from '../components/cta-card';
import useSubscriptionContext from '../hooks/subscription-context';
import Page, { PageBody, PageHeader } from '../components/page';
import Spinner from '../components/spinner';
import {
  CURRENCY_SYMBOL_MAP,
  SUBSCRIPTION_PLAN_CONTENT_MAP,
} from '@common/constants/stripe';
import toast from '../utils/toast';
import { domainPath, pagePath } from '../constants/routes';
import Button from '../components/button';
import { isBrowser } from '../utils/browser';

export interface StripeProduct {
  id: string;
  name: string;
  description: string;
  active: boolean;
  livemode: boolean;
  metadata: {
    subscriptionType: SubscriptionType;
  };
}

export interface StripePrice {
  id: string;
  currency: 'gbp' | 'usd';
  unit_amount: number;
  product: StripeProduct;
  recurring: {
    interval: 'year' | 'month';
  };
}

export interface StripePricesQueryResponse {
  allStripePrice: {
    edges: { node: StripePrice }[];
  };
}

/**
 * Transforms a list of prices pulled from Stripe.
 *
 * Filters out products without the subscription type metadata. Maps and inserts
 * static content stored in code. Sorts by price ascending.
 */
const transformStripePrices = (queryResponse: StripePricesQueryResponse) => {
  return queryResponse.allStripePrice.edges
    .filter(({ node: { product: { metadata: { subscriptionType } } } }) =>
      ['INDIVIDUAL', 'TEAM'].includes(subscriptionType)
    )
    .map(({ node }) => ({
      ...node,
      content:
        SUBSCRIPTION_PLAN_CONTENT_MAP[node.product.metadata.subscriptionType],
    }))
    .sort((a, b) => a.unit_amount - b.unit_amount);
};

const stripeLoadAsync = () => loadStripe(process.env.GATSBY_STRIPE_API_KEY);

/**
 * The payment flow requires a user account to exist before taking a payment.
 *
 * When the user is logged in, Stripe Checkout will launch on clicking a plan
 * signup button.
 *
 * When the user is not logged in, clicking a plan signup button will carry the
 * selected subscription in Auth0 state whilst logging in or creating a new user
 * account. The redirect callback brings them back here, reads the state and
 * forwards them onto Stripe checkout to continue where they left off.
 */
const PricingPage = ({
  location,
}: PageProps<unknown, unknown, SignupState>) => {
  const stripePrices = useStaticQuery<StripePricesQueryResponse>(graphql`
    query PricesQuery {
      allStripePrice {
        edges {
          node {
            id
            currency
            unit_amount
            recurring {
              interval
            }
            product {
              id
              name
              description
              active
              livemode
              metadata {
                subscriptionType
              }
            }
          }
        }
      }
    }
  `);

  const prices = transformStripePrices(stripePrices);

  const { currentPlan, isActive } = useSubscriptionContext();
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    user,
  } = useAuth0();

  const { useLambdaAPI } = useAPIHelper();
  const [, fetchCheckoutSession] = useLambdaAPI(
    { url: '/checkout-start' },
    { manual: true }
  );

  const handleSelectPlan = useCallback(
    async (priceId: string, subscriptionType: SubscriptionType) => {
      // Redirect user to login or create an account before subscribing
      if (!isAuthenticated)
        return await loginWithRedirect({
          appState: {
            returnTo: pagePath.pricing, // This forces internal navigation to ensure we get the callback state in Gatsby context
            selectedSubscription: { priceId, subscriptionType },
          } as SignupState,
        });

      try {
        // Get a scoped access token for the authenticated user
        const accessToken = await getAccessTokenSilently({
          audience: `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/`,
          scope: 'read:current_user update:current_user_metadata',
        });

        // Request a new Stripe Checkout session for the selected subscription
        const {
          data: { sessionId },
        } = await fetchCheckoutSession({
          headers: { Authorization: accessToken },
          params: {
            customerId: user?.[Auth0MirosignCustomNamespace.CustomerId],
            priceId,
            subscriptionType,
            userId: user?.sub,
          },
        });

        const stripe = await stripeLoadAsync();

        if (!stripe) {
          throw new Error('Could not load Stripe');
        }

        // Redirect the user to Stripe Checkout with their valid session
        const { error } = await stripe.redirectToCheckout({ sessionId });

        if (error) {
          throw new Error(error.message);
        }
      } catch (error) {
        toast.error('Something went wrong');
        console.log(error);
      }
    },
    [
      fetchCheckoutSession,
      getAccessTokenSilently,
      isAuthenticated,
      loginWithRedirect,
      user,
    ]
  );

  const handleBespokeClick = () => {
    if (!isBrowser()) return;
    window.location.href = domainPath.contact;
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    /**
     * If the user arrives here having created a new account via plan selection,
     * the page should receive extra state about what plan they selected. If the
     * state is set, we trigger the plan select handler to kick off a new Stripe
     * checkout session.
     */
    const checkSignupStateAndContinue = async () => {
      const signupState = location.state;

      if (!signupState?.selectedSubscription) return;

      const { priceId, subscriptionType } = signupState.selectedSubscription;

      await handleSelectPlan(priceId, subscriptionType);
    };

    checkSignupStateAndContinue();
  }, [location, isAuthenticated, handleSelectPlan]);

  if (isLoading) return <Spinner isLoading={isLoading} />;

  return (
    <Page>
      <PageHeader location={location} />

      <PageBody>
        <div className={styles.Pricing__cards}>
          {prices.map(
            ({
              id,
              content,
              currency,
              unit_amount,
              recurring: { interval },
              product: {
                description,
                metadata: { subscriptionType },
              },
            }) => {
              const isCurrentPlan =
                !!currentPlan && currentPlan === subscriptionType;

              const subscribeText =
                isCurrentPlan && isActive ? 'Purchased' : 'Sign up';

              const pricingExtras =
                content?.label === 'Team' ? ', unlimited signatures' : '';

              return (
                <CTACard
                  key={id}
                  actionText={subscribeText}
                  handleClick={() => handleSelectPlan(id, subscriptionType)}
                  isButtonDisabled={isActive}
                  title={content?.label ?? ''}
                >
                  {content?.features && (
                    <ul className={styles.Pricing__features}>
                      {[description, ...content.features].map((feature) => (
                        <li key={nanoid()} className={styles.Pricing__feature}>
                          {feature}
                        </li>
                      ))}
                    </ul>
                  )}

                  <p className={styles.Pricing__price}>
                    <span className={styles.Pricing__amount}>
                      {CURRENCY_SYMBOL_MAP[currency] + unit_amount / 100}
                    </span>
                    <span className={styles.Pricing__term}>
                      {`per ${interval}`}
                      {pricingExtras}
                    </span>
                  </p>
                </CTACard>
              );
            }
          )}
        </div>

        <div className={styles.Pricing__bespoke}>
          <h2>{SUBSCRIPTION_PLAN_CONTENT_MAP['BESPOKE']?.label}</h2>

          {SUBSCRIPTION_PLAN_CONTENT_MAP['BESPOKE']?.features?.map(
            (feature, index) => (
              <p key={index}>{feature}</p>
            )
          )}

          <Button onClick={handleBespokeClick} size="large" theme="secondary">
            Talk to us
          </Button>
        </div>
      </PageBody>
    </Page>
  );
};

export interface SignupState extends AppState {
  /**
   * Stores the subscription type selected by the user. State carried through
   * Auth0 during signup via plan selection. This value is read during auth
   * callback to forward the user onto Stripe checkout.
   */
  selectedSubscription?: {
    priceId: string;
    subscriptionType: SubscriptionType;
  };
}

export default PricingPage;
