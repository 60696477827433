import { SubscriptionPlanContent, SubscriptionType } from '../types';

export const CURRENCY_SYMBOL_MAP = {
  gbp: '£',
  usd: '$',
};

export const SUBSCRIPTION_PLAN_CONTENT_MAP: Partial<
  Record<SubscriptionType, SubscriptionPlanContent>
> = {
  INDIVIDUAL: {
    label: 'Individual',
    features: [
      'Access to our email signature template generator',
      'Logo image hosting',
      'Updatable banner image hosting for promos and accreditations',
    ],
  },
  TEAM: {
    label: 'Team',
    features: [
      'Access to our email signature template generator',
      'Logo image hosting',
      'Updatable banner image hosting for promos and accreditations',
      'Your own hosted company signature generator',
      'Allow staff to generate unlimited email signatures',
    ],
  },
  BESPOKE: {
    label: 'Bespoke',
    features: [
      'If our templates and generator don’t quite meet your needs, please get in touch and we can work with you to build your perfect bespoke HTML email signature.',
    ],
  },
};

/**
 * These are all the possible IP addresses Stripe Webhook events can be sent
 * from, as per their documentation at https://stripe.com/docs/ips
 * We only check against this list in production.
 */
export const STRIPE_WEBHOOK_IPS = [
  '3.18.12.63',
  '3.130.192.231',
  '13.235.14.237',
  '13.235.122.149',
  '35.154.171.200',
  '52.15.183.38',
  '54.187.174.169',
  '54.187.205.235',
  '54.187.216.72',
  '54.241.31.99',
  '54.241.31.102',
  '54.241.34.107',
];
